import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

function createParagraphMarkup(text) {
  return { __html: text }
}

class HeadingParagraphButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingColumns={
          <>
          <div className="column is-4"></div>
          <div className="column has-text-centered">
            <h3 style={{marginBottom: "1.5rem"}}>{this.props.heading}</h3>
          </div>
          <div className="column is-4"></div>
          </>
        }
        textColumns={
          <div className="column has-text-centered">
            <p dangerouslySetInnerHTML={createParagraphMarkup(this.props.paragraph)}></p>
          </div>
        }
        buttonColumns={
          <div className="column">
            <Button
              contained
              href={this.props.buttonUrl}
              buttonText={this.props.buttonText}
            />
          </div>
        }
      />
    )
  }
}

HeadingParagraphButton.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
}

export default HeadingParagraphButton
